import PropTypes from 'prop-types';
import { Component } from 'react';

import classNames from 'clsx';
import { Link } from 'react-router-dom';
import { LocationAndLocalizationContext } from '../../../providers/LocationAndLocalizationProvider';
import css from './hero-text.module.scss';

export default class HeroText extends Component {
  static propTypes = {
    element: PropTypes.shape({
      line1: PropTypes.string.isRequired,
      line2: PropTypes.string.isRequired,
      line3: PropTypes.string,
      href: PropTypes.string,
    }).isRequired,
    handleLinkClick: PropTypes.func,
    areColorsInverted: PropTypes.bool,
    highlightColor: PropTypes.string,
  };

  static contextType = LocationAndLocalizationContext;

  getElementText(element, key) {
    return element.isDefault && element[key]
      ? this.context.getLocalizedText(element[key]).toUpperCase()
      : element[key].toUpperCase();
  }

  getLinks() {
    const { element, areColorsInverted, highlightColor } = this.props;
    const linkColor = areColorsInverted ? css.grey : css.white;
    const highlightLinkColor = highlightColor || null;

    const links = [
      <Link
        key="line1"
        to={element.href}
        className={classNames(linkColor, {
          [css.showCTA]: element.isCarouselUpsell,
        })}
        data-testid="line1"
        onClick={this.handleClick}
      >
        {this.getElementText(element, 'line1')}
      </Link>,
      <Link
        key="line2"
        to={element.href}
        className={classNames(linkColor, {
          [css.showCTA]: element.isCarouselUpsell,
        })}
        data-testid="line2"
        onClick={this.handleClick}
      >
        {this.getElementText(element, 'line2')}
      </Link>,
    ];

    if (element.line3) {
      links.push(
        <Link
          key="line3"
          to={element.href}
          className={classNames(css.ink, {
            [css.showCTA]: element.isCarouselUpsell,
          })}
          style={{
            color: `${highlightLinkColor || ''}`,
          }}
          data-testid="line3"
          onClick={this.handleClick}
        >
          {this.getElementText(element, 'line3')}
        </Link>,
      );
    }
    return links;
  }

  getParagraphs() {
    const { element, areColorsInverted, highlightColor } = this.props;
    const linkColor = areColorsInverted ? css.grey : css.white;
    const highlightLinkColor = highlightColor || null;

    const paragraphs = [
      <p
        key="line1"
        className={classNames(linkColor, {
          [css.showCTA]: element.isCarouselUpsell,
        })}
        data-testid="line1"
      >
        {this.getElementText(element, 'line1')}
      </p>,
      <p
        key="line2"
        className={classNames(linkColor, {
          [css.showCTA]: element.isCarouselUpsell,
        })}
        data-testid="line2"
      >
        {this.getElementText(element, 'line2')}
      </p>,
    ];

    if (element.line3) {
      paragraphs.push(
        <p
          key="line3"
          className={classNames(css.ink, {
            [css.showCTA]: element.isCarouselUpsell,
          })}
          style={{
            color: `${highlightLinkColor || ''}`,
          }}
          data-testid="line3"
        >
          {this.getElementText(element, 'line3')}
        </p>,
      );
    }
    return paragraphs;
  }

  handleClick = () => {
    if (this.props.handleLinkClick) {
      this.props.handleLinkClick();
    }
  };

  render() {
    const { element } = this.props;

    return (
      <div data-testid="heroTextElement" className={css.element}>
        {element.href ? this.getLinks() : this.getParagraphs()}
      </div>
    );
  }
}
