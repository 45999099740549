import PropTypes from 'prop-types';
import { Component } from 'react';

import css from './hero-header.module.scss';

export default class HeroHeader extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    dataTestId: PropTypes.string.isRequired,
  };

  render() {
    return (
      <div data-testid={this.props.dataTestId} className={css.wrapper}>
        {this.props.children}
      </div>
    );
  }
}
