import classNames from 'clsx';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import connectWithPlayer from '../../../decorators/connectWithPlayer';
import assetUrl from '../../../utils/assetUrl';
import getTunedGuideId from '../../../utils/guideItem/getTunedGuideId';
import PlayTile from '../../containerItems/shared/PlayTile';

import css from './image-block.module.scss';

const ImageLink = ({
  imageObj,
  guideItem,
  playerActions,
  syncedPlayerStatus,
  syncedPlayerAction,
  isSyncedWithPlayer,
  canScrub,
  isMobile,
  dataTestId,
}) => {
  const handlePlayAction = (event) => {
    if (event) event.preventDefault();
    syncedPlayerAction(playerActions, canScrub);
  };
  const clickHandler = isSyncedWithPlayer ? handlePlayAction : noop;

  return (
    <Link data-testid={dataTestId} to={imageObj.href} onClick={clickHandler}>
      {guideItem && (
        <PlayTile
          syncedPlayerStatus={syncedPlayerStatus}
          canScrub={canScrub}
          isMobile={isMobile}
          canControlPlayback={!!getTunedGuideId(guideItem)}
        />
      )}
      <img
        className={classNames(css.image, {
          [css.needsBorder]: imageObj.needsBorder,
        })}
        src={assetUrl(imageObj.imageUrl)}
        alt={imageObj.imageUrl}
      />
    </Link>
  );
};

ImageLink.propTypes = {
  canScrub: PropTypes.bool,
  imageObj: PropTypes.object.isRequired,
  guideItem: PropTypes.object.isRequired,
  playerActions: PropTypes.object.isRequired,
  isSyncedWithPlayer: PropTypes.bool.isRequired,
  syncedPlayerStatus: PropTypes.string.isRequired,
  syncedPlayerAction: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  dataTestId: PropTypes.string.isRequired,
};

export default connectWithPlayer(ImageLink);
