import PropTypes from 'prop-types';
import { Component } from 'react';
import { Link } from 'react-router-dom';

import { isAbsoluteUrl } from 'src/common/components/utils/isAbsoluteUrl';
import { LANDING_HERO_IMAGE_TEXT } from 'src/common/constants/localizations/landing';
import assetUrl from 'src/common/utils/assetUrl';
import { LocationAndLocalizationContext } from '../../../providers/LocationAndLocalizationProvider';
import css from './hero-image.module.scss';

export default class HeroImage extends Component {
  static propTypes = {
    element: PropTypes.shape({
      imageUrl: PropTypes.string.isRequired,
      href: PropTypes.string,
    }).isRequired,
    dataTestId: PropTypes.string,
    handleImageClick: PropTypes.func,
  };

  static contextType = LocationAndLocalizationContext;

  handleClick = () => {
    if (this.props.handleImageClick) {
      this.props.handleImageClick();
    }
  };

  render() {
    const { element, dataTestId } = this.props;
    const { getLocalizedText } = this.context;

    let imageUrl = `${assetUrl(element.imageUrl)}`;

    if (isAbsoluteUrl(element.imageUrl)) {
      imageUrl = `"${element.imageUrl}"`;
    }
    const imageElement = (
      <div
        key={element.imageUrl}
        className={css.image}
        data-testid="heroImage"
        style={{ backgroundImage: `url(${imageUrl})` }}
      />
    );

    if (element.href) {
      return (
        <Link
          to={element.href}
          data-testid={dataTestId}
          onClick={this.handleClick}
        >
          {imageElement}
          <span data-testid="heroImageSRText" className={css.srOnly}>
            {getLocalizedText(LANDING_HERO_IMAGE_TEXT)}
          </span>
        </Link>
      );
    }

    return imageElement;
  }
}
