import PropTypes from 'prop-types';
import { Component } from 'react';

import css from './hero-image.module.scss';

export default class HeroImageContainer extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    return (
      <div data-testid="heroImageContainer" className={css.container}>
        <div className={css.content}>{this.props.children}</div>
      </div>
    );
  }
}
