import classNames from 'clsx';
import PropTypes from 'prop-types';
import ImageLink from './ImageLink';

import css from './image-block.module.scss';

const ImageBlock = ({ dataTestId, isFirstImageSmall, imageBlock }) => (
  <div className={css.content}>
    <div
      data-testid={`parallax-imageBlock-${dataTestId}`}
      className={css.images}
    >
      <div className={css.imageSectionLeft}>
        <div
          data-testid={`parallax-imageContainer-${dataTestId}-0`}
          className={classNames(css.imageContainer, {
            [css.padTop]: isFirstImageSmall,
            [css.correctLeftImageOverlay]: isFirstImageSmall,
          })}
        >
          <ImageLink
            imageObj={imageBlock[0]}
            guideItem={imageBlock[0].guideItem}
            dataTestId={`parallax-image-${dataTestId}-0`}
          />
        </div>
        <div
          data-testid={`parallax-imageContainer-${dataTestId}-1`}
          className={classNames(css.imageContainer, {
            [css.correctLeftImageOverlay]: !isFirstImageSmall,
          })}
        >
          <ImageLink
            imageObj={imageBlock[1]}
            guideItem={imageBlock[1].guideItem}
            dataTestId={`parallax-image-${dataTestId}-1`}
          />
        </div>
      </div>
      <div className={css.imageSectionRight}>
        <div
          data-testid={`parallax-imageContainer-${dataTestId}-2`}
          className={classNames(css.imageContainer, {
            [css.padTop]: !isFirstImageSmall,
            [css.correctRightImageOverlay]: !isFirstImageSmall,
          })}
        >
          <ImageLink
            imageObj={imageBlock[2]}
            guideItem={imageBlock[2].guideItem}
            dataTestId={`parallax-image-${dataTestId}-2`}
          />
        </div>
        <div
          data-testid={`parallax-imageContainer-${dataTestId}-3`}
          className={classNames(css.imageContainer, {
            [css.correctRightImageOverlay]: isFirstImageSmall,
          })}
        >
          <ImageLink
            imageObj={imageBlock[3]}
            guideItem={imageBlock[3].guideItem}
            dataTestId={`parallax-image-${dataTestId}-3`}
          />
        </div>
      </div>
    </div>
  </div>
);

ImageBlock.propTypes = {
  dataTestId: PropTypes.string.isRequired,
  isFirstImageSmall: PropTypes.bool.isRequired,
  imageBlock: PropTypes.array.isRequired,
};

export default ImageBlock;
